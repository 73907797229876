import axios from "axios";
import { getHeader, roleUrl, timezoneUrl, userUrl } from "@/constant/config";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  state: {
    timezoneList: [],
    roleList: [],
    userList: [],
    permissionList: [],
    showRoleFormModal: false
  },
  mutations: {
    SET_TIMEZONE_LIST (state, obj) {
      state.timezoneList = obj
    },
    SET_ROLE_LIST (state, obj) {
      state.roleList = obj
    },
    SET_USER_LIST (state, obj) {
      state.userList = obj
    },
    SET_PERMISSION_LIST (state, obj) {
      state.permissionList = obj
    },
    SET_ROLE_FORM_MODAL (state, obj) {
      state.showRoleFormModal = obj
    }
  },
  actions: {
    enableRoleFormModal ({ commit }) {
      commit('SET_ROLE_FORM_MODAL', true)
    },
    disableRoleFormModal ({ commit }) {
      commit('SET_ROLE_FORM_MODAL', false)
    },
    async getPermissionList ({ commit }) {
      try {
        console.log(userUrl  + '/permission')
        const data = await axios.get(userUrl  + '/permission', { headers: getHeader() })
        commit('SET_PERMISSION_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getUserList ({ commit }) {
      try {
        const data = await axios.get(userUrl  + '/list', { headers: getHeader() })
        commit('SET_USER_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.response)
        toast.error(error.response.data.message, { timeout: 2000 })
      }
    },
    async getRoleList({ commit }) {
      try {
        const data = await axios.get(roleUrl, { headers: getHeader() })
        commit('SET_ROLE_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getTimezonelist({ commit }) {
      try {
        const data = await axios.get(timezoneUrl, { headers: getHeader() })
        console.log(data)
        commit('SET_TIMEZONE_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    }
  },
};
