import axios from "axios";
import { approvalUrl, getHeader } from "@/constant/config";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  state: {
    approvalTypeList: [],
    approvalRefNoList: [],
    approval: {},
    showApprovalTypeFormModal: false,
    showApprovalRefNoListModal: false,
    showApprovalModal: false,
    showApprovalVoidModal: false,
    showApprovalRequestModal: false
  },
  mutations: {
    SET_APPROVAL_REF_NO_LIST (state, obj) {
      state.approvalRefNoList = obj
    },
    SET_APPROVAL_TYPE_LIST (state, obj) {
      state.approvalTypeList = obj
    },
    SET_SHOW_APPROVAL_TYPE_FORM_MODAL (state, obj) {
      state.showApprovalTypeFormModal = obj
    },
    SET_SHOW_APPROVAL_REF_NO_LIST_MODAL (state, obj) {
      state.showApprovalRefNoListModal = obj
    },
    SET_APPROVAL_BY_ID (state, obj) {
      state.approval = obj
    },
    SET_SHOW_APPROVAL_MODAL (state, obj) {
      state.showApprovalModal = obj
    },
    SET_SHOW_APPROVAL_VOID_MODAL (state, obj) {
      state.showApprovalVoidModal = obj
    },
    SET_SHOW_APPROVAL_REQUEST_MODAL (state, obj) {
      state.showApprovalRequestModal = obj
    }
  },
  actions: {
    enableShowApprovalRequestModal ({ commit }) {
      commit('SET_SHOW_APPROVAL_REQUEST_MODAL', true)
    },
    disableShowApprovalRequestModal ({ commit }) {
      commit('SET_SHOW_APPROVAL_REQUEST_MODAL', false)
    },
    enableShowApprovalVoidModal ({ commit }) {
      commit('SET_SHOW_APPROVAL_VOID_MODAL', true)
    },
    disableShowApprovalVoidModal ({ commit }) {
      commit('SET_SHOW_APPROVAL_VOID_MODAL', false)
    },
    enableShowApprovalModal ({ commit }) {
      commit('SET_SHOW_APPROVAL_MODAL', true)
    },
    disableShowApprovalModal ({ commit }) {
      commit('SET_SHOW_APPROVAL_MODAL', false)
    },
    enableShowApprovalRefNoListModal ({ commit }) {
      commit('SET_SHOW_APPROVAL_REF_NO_LIST_MODAL', true)
    },
    disableShowApprovalRefNoListModal ({ commit }) {
      commit('SET_SHOW_APPROVAL_REF_NO_LIST_MODAL', false)
    },
    enableShowApprovalTypeFormModal ({ commit }) {
      commit('SET_SHOW_APPROVAL_TYPE_FORM_MODAL', true)
    },
    disableShowApprovalTypeFormModal ({ commit }) {
      commit('SET_SHOW_APPROVAL_TYPE_FORM_MODAL', false)
    },
    async getApprovalById ({ commit }, payload) {
      try {
        console.log(approvalUrl + '/' + payload)
        const data = await axios.get(approvalUrl + '/' + payload, { headers: getHeader() })
        commit('SET_APPROVAL_BY_ID', data.data.data)
      } catch (error) {
        console.log(error)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getApprovalRefNoList ({ commit }, payload) {
      try {
        const data = await axios.post(approvalUrl + '/get-approval-ref-no', { ref_no: payload }, { headers: getHeader() })
        commit('SET_APPROVAL_REF_NO_LIST', data.data.data)
      } catch(error) {
        console.log(error)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getApprovalTypeList({ commit }) {
      try {
        const data = await axios.get(approvalUrl + '/type', { headers: getHeader() })
        commit('SET_APPROVAL_TYPE_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        toast.error(error.message, { timeout: 2000 })
      }
    }
  }
};
