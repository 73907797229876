import axios from "axios";
import { stateUrl, getHeader, shippingUrl } from "@/constant/config";
import { useToast } from "vue-toastification";
import { State } from "@amcharts/amcharts5/.internal/core/util/States";
const toast = useToast();

export default {
  state: {
    shippingList: [],
    showShippingListModal: false,
    showShippingCreateBillFormModal: false
  },
  mutations: {
    SET_SHIPPING_LIST (state, obj) {
      state.shippingList = obj
    },
    SET_SHOW_SHIPPING_LIST_MODAL (state, obj) {
      state.showShippingListModal = obj
    },
    SET_SHOW_SHILPPING_CREATE_BILL_FORM_MODAL (state, obj) {
      state.showShippingCreateBillFormModal = obj
    }
  },
  actions: {
    enableShippingCreateBillFormModal ({ commit }) {
      commit('SET_SHOW_SHILPPING_CREATE_BILL_FORM_MODAL', true)
    },
    disableShippingCreateBillFormModal ({ commit }) {
      commit('SET_SHOW_SHILPPING_CREATE_BILL_FORM_MODAL', false)
    },
    enableShippingListModal ({ commit }) {
      commit('SET_SHOW_SHIPPING_LIST_MODAL', true)
    },
    disableShippingListModal ({ commit }) {
      commit('SET_SHOW_SHIPPING_LIST_MODAL', false)
    },
    async getShippingList({ commit }, payload) {
      try {
        const data = await axios.post(shippingUrl + '/list', payload, { headers: getHeader() })
        commit('SET_SHIPPING_LIST', data.data.data)
        console.log('SET_SHIPPING_LIST')
        console.log(data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    }
  },
};
