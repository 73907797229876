import axios from "axios";
import { getHeader, warehouseUrl } from "@/constant/config";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  state: {
    warehouseList: [],
    showWarehouseFormModal: false
  },
  mutations: {
    SET_WAREHOUSE_LIST (state, obj) {
      state.warehouseList = obj
    },
    SET_SHOW_WAREHOUSE_FORM_MODAL (state, obj) {
      state.showWarehouseFormModal = obj
    }
  },
  actions: {
    enableShowWarehouseFormModal ({ commit }) {
      commit('SET_SHOW_WAREHOUSE_FORM_MODAL', true)
    },
    disableShowWarehouseFormModal ({ commit }) {
      commit('SET_SHOW_WAREHOUSE_FORM_MODAL', false)
    },
    async getWarehouseList({ commit }) {
      try {
        const data = await axios.get(warehouseUrl, { headers: getHeader() })
        commit('SET_WAREHOUSE_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    }
  }
};
