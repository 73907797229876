import axios from "axios";
import { employeeUrl, getHeader } from "@/constant/config";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  state: {
    positionList: [],
    departmentList: [],
    employeeList: [],
    showDepartmentModal: false,
    showDepartmentEditModal: false,
    showPositionModal: false,
    showPositionEditModal: false,
  },
  mutations: {
    SET_POSITION_LIST (state, obj) {
      state.positionList = obj
    },
    SET_DEPARTMENT_LIST (state, obj) {
      state.departmentList = obj
    },
    SET_EMPLOYEE_LIST (state, obj) {
      state.employeeList = obj
    },
    SET_SHOW_DEPARTMENT (state, obj) {
      state.showDepartmentModal = obj
    },
    SET_SHOW_DEPARTMENT_EDIT (state, obj) {
      state.showDepartmentEditModal = obj
    },
    SET_SHOW_POSITION (state, obj) {
      state.showPositionModal = obj
    },
    SET_SHOW_POSITION_EDIT (state, obj) {
      state.showPositionEditModal = obj
    }
  },
  actions: {
    disableModalDepartment ({ commit }) {
      commit('SET_SHOW_DEPARTMENT', false)
    },
    enableModalDepartment ({ commit }) {
      commit('SET_SHOW_DEPARTMENT', true)
    },
    disableModalEditDepartment ({ commit }) {
      commit('SET_SHOW_DEPARTMENT_EDIT', false)
    },
    enableModalEditDepartment ({ commit }) {
      commit('SET_SHOW_DEPARTMENT_EDIT', true)
    },
    disableModalPosition ({ commit }) {
      commit('SET_SHOW_POSITION', false)
    },
    enableModalPosition ({ commit }) {
      commit('SET_SHOW_POSITION', true)
    },
    disableModalEditPosition ({ commit }) {
      commit('SET_SHOW_POSITION_EDIT', false)
    },
    enableModalEditPosition ({ commit }) {
      commit('SET_SHOW_POSITION_EDIT', true)
    },
    async getPositionList({ commit }) {
      try {
        const data = await axios.get(employeeUrl + '/position', { headers: getHeader() })
        commit('SET_POSITION_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getDepartmentList({ commit }) {
      try {
        const data = await axios.get(employeeUrl + '/department', { headers: getHeader() })
        commit('SET_DEPARTMENT_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getEmployeeList({ commit }) {
      try {
        const data = await axios.get(employeeUrl, { headers: getHeader() })
        commit('SET_EMPLOYEE_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    }
  },
};
