import axios from "axios";
import { cityUrl, getHeader } from "@/constant/config";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  state: {
    cityList: []
  },
  mutations: {
    SET_CITY_LIST (state, obj) {
      state.cityList = obj
    }
  },
  actions: {
    async getCityList({ commit }) {
      try {
        const data = await axios.get(cityUrl, { headers: getHeader() })
        commit('SET_CITY_LIST', data.data.data)
      } catch (error) {
        toast.error(error.message, { timeout: 2000 })
      }
    }
  },
};
