import axios from "axios";
import { branchUrl, countryUrl, currencyUrl, exchangeUrl, getHeader, masterUrl, organizationUrl } from "@/constant/config";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  state: {
    paymentMethodList: [],
    stateList: [],
    cityList: [],
    districtList: [],
    villageList: [],
    taxList: [],
    uomList: [],
    warehouseList: [],
    termOfPaymentList: [],
    countryList: [],
    bankList: [],
    priceListList: [],
    currencyList: [],
    exchangeList: [],
    branchList: [],
    organizationList: []
  },
  mutations: {
    SET_CURRENCY_LIST (state, obj) {
      state.currencyList = obj      
    },
    SET_EXCHANGE_LIST (state, obj) {
      state.exchangeList = obj        
    },
    SET_BANK_LIST (state, obj) {
      state.bankList = obj
    },
    SET_PAYMENT_METHOD_LIST (state, obj) {
      state.paymentMethodList = obj
    },
    SET_STATE_LIST (state, obj) {
      state.stateList = obj
    },
    SET_CITY_LIST (state, obj) {
      state.cityList = obj
    },
    SET_DISTRICT_LIST (state, obj) {
      state.districtList = obj
    },
    SET_VILLAGE_LIST (state, obj) {
      state.villageList = obj
    },
    SET_TAX_LIST (state, obj) {
      state.taxList = obj
    },
    SET_UOM_LIST (state, obj) {
      state.uomList = obj
    },
    SET_WAREHOUSE_LIST (state, obj) {
      state.warehouseList = obj
    },
    SET_TERM_OF_PAYMENT_LIST (state, obj) {
      state.termOfPaymentList = obj
    },
    SET_COUNTRY_LIST (state, obj) {
      state.countryList = obj
    },
    SET_PRICE_LIST_LIST (state, obj) {
      state.priceListList = obj
    },
    SET_BRANCH_LIST (state, obj) {
      state.branchList = obj
    },
    SET_ORGANIZATION_LIST (state, obj) {
      state.organizationList = obj
    }
  },
  actions: {
    async getOrganizationList ({ commit }) {
      try {
        const data = await axios.get(organizationUrl, { headers: getHeader() })
        commit("SET_ORGANIZATION_LIST", data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getDistrictList ({ commit }) {
      try {
        const data = await axios.get(masterUrl + '/district', { headers: getHeader() })
        commit("SET_DISTRICT_LIST", data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getVillageList ({ commit }, payload) {
      try {
        const url = masterUrl + '/village?district_id=' + payload.district_id
        const data = await axios.get(url, { headers: getHeader() })
        commit("SET_VILLAGE_LIST", data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getBranchList ({ commit }) {
      try {
        const data = await axios.get(branchUrl, { headers: getHeader() })
        commit('SET_BRANCH_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getCurrencyList({ commit }) {
      try {
        const data = await axios.get(currencyUrl, { headers: getHeader() })
        commit("SET_CURRENCY_LIST", data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getExchangeList({ commit }) {
      try {
        const data = await axios.get(exchangeUrl, { headers: getHeader() })
        commit("SET_EXCHANGE_LIST", data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getPriceListList({ commit }) {
      try {
        const data = await axios.get(masterUrl + '/price-list', { headers: getHeader() })
        commit('SET_PRICE_LIST_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getBankList ({ commit }) {
      try {
        const data = await axios.get(masterUrl + '/bank', { headers: getHeader() })
        commit('SET_BANK_LIST', data.data.data)
      } catch(error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getCountryList ({ commit }) {
      try {
        const data = await axios.get(countryUrl, { headers: getHeader() })
        commit('SET_COUNTRY_LIST', data.data.data)
      } catch(error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getTermOfPaymentList ({ commit }) {
      try {
        const data = await axios.get(masterUrl + '/term-of-payment', { headers: getHeader() })
        commit('SET_TERM_OF_PAYMENT_LIST', data.data.data)
      } catch(error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getPaymentMethodList({ commit }) {
      try {
        const data = await axios.get(masterUrl + '/payment/method', { headers: getHeader() })
        commit('SET_PAYMENT_METHOD_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getStateList({ commit }) {
      try {
        const data = await axios.get(masterUrl + '/state', { headers: getHeader() })
        commit('SET_STATE_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getCityList({ commit }) {
      try {
        const data = await axios.get(masterUrl + '/city', { headers: getHeader() })
        commit('SET_CITY_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getTaxList({ commit }) {
      try {
        const data = await axios.get(masterUrl + '/tax?active=true', { headers: getHeader() })
        commit('SET_TAX_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getUomList({ commit }) {
      try {
        const data = await axios.get(masterUrl + '/uom', { headers: getHeader() })
        commit('SET_UOM_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getWarehouseList({ commit }) {
      try {
        console.log(masterUrl + '/warehouse')
        const data = await axios.get(masterUrl + '/warehouse', { headers: getHeader() })
        console.log(data.data.data)
        commit('SET_WAREHOUSE_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    }
  }
};
