import axios from "axios";
import { currencyUrl, getHeader } from "@/constant/config";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  state: {
    currencyList: []
  },
  mutations: {
    SET_CURRENCY_LIST (state, obj) {
      state.currencyList = obj
    }
  },
  actions: {
    async getCurrencyList({ commit }) {
      try {
        const data = await axios.get(currencyUrl, { headers: getHeader() })
        commit('SET_CURRENCY_LIST', data.data.data)
      } catch (error) {
        toast.error(error.message, { timeout: 2000 })
      }
    }
  },
};
