import axios from "axios";
import { getHeader, priceListUrl, productUrl } from "@/constant/config";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  state: {
    productTypeList: [],
    productCategoryList: [],
    productList: [],
    productMainList: [],
    productUomList: [],
    productVariableList: [],
    productPriceTypeList: [],
    productPriceList: [],
    showProductListModal: false,
    showProductCostSyncModal: false,
    showProductImportModal: false,
    showProductCostSyncModal: false,
    showProductPriceImportModal: false,
    showProductOptionalModal: false,
  },
  mutations: {
    SET_PRODUCT_PRICE_LIST (state, obj) {
      state.productPriceList = obj
    },
    SET_PRODUCT_PRICE_TYPE_LIST (state, obj) {
      state.productPriceTypeList = obj
    },
    SET_PRODUCT_VARIABLE_LIST (state, obj) {
      state.productVariableList = obj
    },
    SET_PRODUCT_TYPE_LIST (state, obj) {
      state.productTypeList = obj
    },
    SET_PRODUCT_CATEGORY_LIST (state, obj) {
      state.productCategoryList = obj
    },
    SET_PRODUCT_LIST (state, obj) {
      state.productList = obj
    },
    SET_PRODUCT_MAIN_LIST (state, obj) {
      state.productMainList = obj
    },
    SET_PRODUCT_LIST_MODAL (state, obj) {
      state.showProductListModal = obj
    },
    SET_PRODUCT_IMPORT_MODAL (state, obj) {
      state.showProductImportModal = obj
    },
    SET_SHOW_PRODUCT_COST_SYNC_MODAL (state, obj) {
      state.showProductCostSyncModal = obj
    },
    SET_PRODUCT_UOM_LIST (state, obj) {
      state.productUomList = obj
    },
    SET_PRODUCT_PRICE_LIST_IMPORT_MODAL (state, obj) {
      state.showProductPriceImportModal = obj
    },
    SET_PRODUCT_OPTIONAL_MODAL (state, obj) {
      state.showProductOptionalModal = obj
    },
  },
  actions: {
    enableProudctPriceListImportModal ({ commit }) {
      commit('SET_PRODUCT_PRICE_LIST_IMPORT_MODAL', true)
    },
    disableProudctPriceListImportModal ({ commit }) {
      commit('SET_PRODUCT_PRICE_LIST_IMPORT_MODAL', false)
    },
    enableProductCostSyncModal({ commit }) {
      commit('SET_SHOW_PRODUCT_COST_SYNC_MODAL', true)
    },
    disableProductCostSyncModal({ commit }) {
      commit('SET_SHOW_PRODUCT_COST_SYNC_MODAL', false)
    },
    enableProductListModal({ commit }) {
      commit('SET_PRODUCT_LIST_MODAL', true)
    },
    disableProductListModal({ commit }) {
      commit('SET_PRODUCT_LIST_MODAL', false)
    },
    enableProductImportModal({ commit }) {
      commit('SET_PRODUCT_IMPORT_MODAL', true)
    },
    disableProductImportModal({ commit }) {
      commit('SET_PRODUCT_IMPORT_MODAL', false)
    },
    enableProductOptionalModal({ commit }) {
      commit('SET_PRODUCT_OPTIONAL_MODAL', true)
    },
    disableProductOptionalModal({ commit }) {
      commit('SET_PRODUCT_OPTIONAL_MODAL', false)
    },
    async getProductPriceList({ commit }) {
      try {
        const data = await axios.get(priceListUrl, { headers: getHeader() })
        commit('SET_PRODUCT_PRICE_LIST', data.data.data)
      } catch(error) {
        console.log(error)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getProductPriceTypeList({ commit }) {
      try {
        const data = await axios.get(productUrl + '/price/type', { headers: getHeader() })
        commit('SET_PRODUCT_PRICE_TYPE_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getProductVariableList({ commit }) {
      try {
        const data = await axios.get(productUrl + '/variable', { headers: getHeader() })
        commit('SET_PRODUCT_VARIABLE_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getProductTypeList({ commit }) {
      try {
        const data = await axios.get(productUrl + '/type', { headers: getHeader() })
        commit('SET_PRODUCT_TYPE_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getProductCategoryList({ commit }) {
      try {
        const data = await axios.get(productUrl + '/category', { headers: getHeader() })
        commit('SET_PRODUCT_CATEGORY_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getProductUomList({ commit }, payload) {
      try {
        const data = await axios.get(productUrl + '/uom', payload, { headers: getHeader() })
        commit('SET_PRODUCT_UOM_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getProductList({ commit }, payload) {
      let canBeSold = true
      let canBePurchase = true
      let active = true
      if (payload) {
        canBeSold = payload.canBeSold == null ? true : payload.canBeSold
        canBePurchase = payload.canBePurchase == null ? true : payload.canBePurchase
        active = payload.active == null ? true : payload.active
      } else {
        canBeSold = true
        canBePurchase = true
        active = true
      }
      const url = productUrl + '/list?can_be_sold=' + canBeSold + '&can_be_purchase=' + canBePurchase + '&active=' + active
      console.log(url)
      try {
        const data = await axios.get(url, { headers: getHeader() })
        // console.log(data.data.data)
        commit('SET_PRODUCT_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getProductMainList({ commit }, payload) {
      let canBeSold = true
      let canBePurchase = true
      let active = true
      if (payload) {
        canBeSold = payload.canBeSold == null ? true : payload.canBeSold
        canBePurchase = payload.canBePurchase == null ? true : payload.canBePurchase
        active = payload.active == null ? true : payload.active
      } else {
        canBeSold = true
        canBePurchase = true
        active = true
      }
      const url = productUrl + '/list-main?can_be_sold=' + canBeSold + '&can_be_purchase=' + canBePurchase + '&active=' + active
      try {
        const data = await axios.get(url, { headers: getHeader() })
        // console.log(data.data.data)
        commit('SET_PRODUCT_MAIN_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    }
  }
};
