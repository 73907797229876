import axios from "axios";
import { getHeader, unitUrl, unitCheckUrl, unitTripUrl } from "@/constant/config";
import { useToast } from "vue-toastification";
import moment from "moment-timezone";
const toast = useToast();

export default {
  state: {
    unitList: [],
    unitTypeList: [],
    unitCategoryList: [],
    unitListCustomer: [],
    unitCheckList: [],
    unitTripList: [],
    unitCheckOptionList: [],
    unitGroupList: [],
    unitGroupTableList: [],
    showUnitModal: false,
    showNewUnitDataModal: false,
    showUnitTripFormModal: false,
    showAdditionalCostModal: false,
    showUnitTripDetailFormModal: false,
    showUnitTripCreateNextModal: false
  },
  mutations: {
    SET_UNIT_LIST (state, obj) {
      state.unitList = obj
    },
    SET_UNIT_TYPE_LIST (state, obj) {
      state.unitTypeList = obj
    },
    SET_UNIT_CATEGORY_LIST (state, obj) {
      state.unitCategoryList = obj
    },
    SET_UNIT_CHECK_LIST (state, obj) {
      state.unitCheckList = obj
    },
    SET_UNIT_TRIP_LIST (state, obj) {
      state.unitTripList = obj
    },
    SET_UNIT_CHECK_OPTION_LIST (state, obj) {
      state.unitCheckOptionList = obj
    },
    SET_UNIT_GROUP_LIST (state, obj) {
      state.unitGroupList = obj
    },
    SET_UNIT_GROUP_TABLE_LIST (state, obj) {
      state.unitGroupTableList = obj
    },
    SET_SHOW_UNIT_MODAL (state, obj) {
      state.showUnitModal = obj
    },
    SET_SHOW_NEW_UNIT_DATA_MODAL (state, obj) {
      state.showNewUnitDataModal = obj
    },
    SET_SHOW_UNIT_TRIP_FORM_MODAL (state, obj) {
      state.showUnitTripFormModal = obj
    },
    SET_SHOW_ADDITIONAL_COST_MODAL (state, obj) {
      state.showAdditionalCostModal = obj
    },
    SET_SHOW_UNIT_TRIP_DETAIL_FORM_MODAL ( state, obj ) {
      state.showUnitTripDetailFormModal = obj
    },
    SET_UNIT_LIST_CUSTOMER (state, obj) {
      state.unitListCustomer = obj
    },
    SET_UNIT_TRIP_CREATE_NEXT_MODAL (state, obj) {
      state.showUnitTripCreateNextModal = obj
    }
  },
  actions: {
    enableUnitTripCreateNextModal ({ commit }) {
      commit('SET_UNIT_TRIP_CREATE_NEXT_MODAL', true)
    },
    disableUnitTripCreateNextModal ({ commit }) {
      commit('SET_UNIT_TRIP_CREATE_NEXT_MODAL', false)
    },
    enableUnitModal ({ commit }) {
      commit('SET_SHOW_UNIT_MODAL', true)
    },
    disableUnitModal ({ commit }) {
      commit('SET_SHOW_UNIT_MODAL', false)
    },
    enableNewUnitDataModal ({ commit }) {
      commit('SET_SHOW_NEW_UNIT_DATA_MODAL', true)
    },
    disableNewUnitDataModal ({ commit }) {
      commit('SET_SHOW_NEW_UNIT_DATA_MODAL', false)
    },
    enableAdditionalCostModal ({ commit }) {
      commit('SET_SHOW_ADDITIONAL_COST_MODAL', true)
    },
    disableAdditionalCostModal ({ commit }) {
      commit('SET_SHOW_ADDITIONAL_COST_MODAL', false)
    },
    enableUnitTripDetailFormModal ({ commit }) {
      commit('SET_SHOW_UNIT_TRIP_DETAIL_FORM_MODAL', true)
    },
    disableUnitTripDetailFormModal ({ commit }) {
      commit('SET_SHOW_UNIT_TRIP_DETAIL_FORM_MODAL', false)
    },
    enableShowUnitTripFormModal ({ commit }) {
      commit('SET_SHOW_UNIT_TRIP_FORM_MODAL', true)
    },
    disableShowUnitTripFormModal ({ commit }) {
      commit('SET_SHOW_UNIT_TRIP_FORM_MODAL', false)
    },
    async getUnitGroupList({ commit }) {
      try {
        const data = await axios.get(unitUrl + '/group', { headers: getHeader() })
        commit('SET_UNIT_GROUP_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
      
    },
    async getUnitGroupTableList({ commit }) {
      try {
        const data = await axios.get(unitUrl + '/group-table', { headers: getHeader() })
        commit('SET_UNIT_GROUP_TABLE_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    enableShowUnitTripFormModal ({ commit }) {
      commit('SET_SHOW_UNIT_TRIP_FORM_MODAL', true)
    },
    disableShowUnitTripFormModal ({ commit }) {
      commit('SET_SHOW_UNIT_TRIP_FORM_MODAL', false)
    },
    async getUnitTypeList({ commit }) {
      try {
        const data = await axios.get(unitUrl + '/type', { headers: getHeader() })
        commit('SET_UNIT_TYPE_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getUnitCategoryList({ commit }) {
      try {
        const data = await axios.get(unitUrl + '/category', { headers: getHeader() })
        commit('SET_UNIT_CATEGORY_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getUnitList({ commit }) {
      console.log('getUnitList')
      try {
        const data = await axios.get(unitUrl, { headers: getHeader() })
        commit('SET_UNIT_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getUnitCheckList({ commit }) {
      try {
        const data = await axios.get(unitCheckUrl, { headers: getHeader()})
        commit('SET_UNIT_CHECK_LIST', data.data.data)
      } catch(error){
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getUnitTripList({ commit }) {
      try {
        const data = await axios.get(unitTripUrl + '?' + { begin: moment().format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') }, { headers: getHeader()})
        commit('SET_UNIT_TRIP_LIST', data.data.data)
      } catch(error){
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getUnitCheckOptionList({ commit }) {
      try {
        const data = await axios.get(unitCheckUrl + '/option', { headers: getHeader()})
        commit('SET_UNIT_CHECK_OPTION_LIST', data.data.data)
      } catch(error){
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getUnitByCustomer({ commit }, payload) {
      try {
        const data = await axios.post(unitUrl + '/get-by-customer', payload, { headers: getHeader() })
        commit('SET_UNIT_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    }
  }
};
