import { getHeader, invoiceUrl } from "@/constant/config";
import axios from "axios";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  state: {
    invoice: {
      id: null,
      invoice_no: null,
      details: [{
        id: null
      }]
    },
    invoiceList: [],
    showInvoiceShowModal: false,
    showInvoiceListModal: false,
    showInvoiceReturModal: false,
    showInvoiceDetailFormModal: false
  },
  mutations: {
    SET_INVOICE_LIST (state, obj) {
      state.invoiceList = obj
    },
    SET_INVOICE (state, obj) {
      state.invoice = obj
    },
    SET_SHOW_INVOICE_SHOW_MODAL (state, obj) {
      state.showInvoiceShowModal = obj
    },
    SET_SHOW_INVOICE_LIST_MODAL (state, obj) {
      state.showInvoiceListModal = obj
    },
    SET_SHOW_INVOICE_RETUR_MODAL (state, obj) {
      state.showInvoiceReturModal = obj
    },
    SET_SHOW_INVOICE_FORM_MODAL (state, obj) {
      state.showInvoiceDetailFormModal = obj
    }
  },
  actions: {
    enableShowInvoiceFormModal ({ commit }) {
      commit('SET_SHOW_INVOICE_FORM_MODAL', true)
    },
    disableShowInvoiceFormModal ({ commit }) {
      commit('SET_SHOW_INVOICE_FORM_MODAL', false)
    },
    enableShowInvoiceReturModal ({ commit }) {
      commit('SET_SHOW_INVOICE_RETUR_MODAL', true)
    },
    disableShowInvoiceReturModal ({ commit }) {
      commit('SET_SHOW_INVOICE_RETUR_MODAL', false)
    },
    enableInvoiceListModal ({ commit }) {
      commit('SET_SHOW_INVOICE_LIST_MODAL', true)
    },
    disableInvoiceListModal ({ commit }) {
      commit('SET_SHOW_INVOICE_LIST_MODAL', false)
    },
    enableInvoiceShowModal ({ commit }) {
      commit('SET_SHOW_INVOICE_SHOW_MODAL', true)
    },
    disableInvoiceShowModal ({ commit }) {
      commit('SET_SHOW_INVOICE_SHOW_MODAL', false)
    },
    async getInvoiceList ({ commit }, payload) {
      try {
        const url = invoiceUrl + '?begin=' + payload.begin + '&end=' + payload.end
        const data = await axios.get(url, { headers: getHeader() })
        commit('SET_INVOICE_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getInvoice({ commit }, payload) {
      try {
        const url = invoiceUrl + '/' + payload.id
        const data = await axios.get(url, { headers: getHeader() })
        console.log(data.data.data)
        commit('SET_INVOICE', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getInvoiceByContact({ commit }, payload) {
      try {
        const url = invoiceUrl + '/list'
        const data = await axios.post(url, payload, { headers: getHeader() })
        commit('SET_INVOICE_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    }
  }
};
