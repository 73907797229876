import axios from "axios";
import { getHeader, organizationUrl } from "@/constant/config";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  state: {
    organizationList: []
  },
  mutations: {
    SET_ORGANIZATION_LIST (state, obj) {
      state.organizationList = obj
    }
  },
  actions: {
    async getOrganizationList({ commit }) {
      try {
        const data = await axios.get(organizationUrl, { headers: getHeader() })
        commit('SET_ORGANIZATION_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    }
  }
};
