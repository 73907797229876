import axios from "axios";
import { contactUrl, getHeader } from "@/constant/config";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  state: {
    vendorList: [],
    customerList: [],
    showContactPersonModal: false,
    showContactFormModal: false,
    showContactTableModal: false,
    contactList: [],
    showContactSearchIdentityModal: false
  },
  mutations: {
    SET_VENDOR_LIST (state, obj) {
      state.vendorList = obj
    },
    SET_CUSTOMER_LIST (state, obj) {
      state.customerList = obj
    },
    SET_CONTACT_PERSON_MODAL (state, obj) {
      state.showContactPersonModal = obj
    },
    SET_CONTACT_LIST (state, obj) {
      state.contactList = obj
    },
    SET_CONTACT_FORM_MODAL (state, obj) {
      state.showContactFormModal = obj
    },
    SET_CONTACT_TABLE_MODAL (state, obj) {
      state.showContactTableModal = obj
    },
    SET_SHOW_CONTACT_SEARCH_IDENTITY_MODAL (state, obj) {
      state.showContactSearchIdentityModal = obj
    }
  },
  actions: {
    enableContactSearchIdentityModal ({ commit }) {
      commit('SET_SHOW_CONTACT_SEARCH_IDENTITY_MODAL', true)
    },
    disableContactSearchIdentityModal ({ commit }) {
      commit('SET_SHOW_CONTACT_SEARCH_IDENTITY_MODAL', false)
    },
    enableContactFormModal ({ commit }) {
      commit('SET_CONTACT_FORM_MODAL', true)
    },
    disableContactFormModal ({ commit }) {
      commit('SET_CONTACT_FORM_MODAL', false)
    },
    enableContactTableModal ({ commit }) {
      commit('SET_CONTACT_TABLE_MODAL', true)
    },
    disableContactTableModal ({ commit }) {
      commit('SET_CONTACT_TABLE_MODAL', false)
    },
    enableContactPersonModal ({ commit }) {
      commit('SET_CONTACT_PERSON_MODAL', true)
    },
    disableContactPersonModal ({ commit }) {
      commit('SET_CONTACT_PERSON_MODAL', false)
    },
    async getVendorList({ commit }) {
      try {
        const data = await axios.get(contactUrl + '?type=vendor', { headers: getHeader() })
        commit('SET_VENDOR_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getCustomerList({ commit }) {
      try {
        const data = await axios.get(contactUrl + '?type=customer', { headers: getHeader() })
        commit('SET_CUSTOMER_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getContactList({ commit }, payload) {
      try { 
        const data = await axios.get(contactUrl, { headers: getHeader() })
        commit('SET_CONTACT_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    }
  }
};
