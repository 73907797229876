import { createStore } from "vuex";
import chat from "./app/chat";
import email from "./app/email";
import kanban from "./app/kanban";
import project from "./app/project";
import apptodo from "./app/todo";
import user from "./app/user";
import auth from "./app/auth";
import employee from "./app/employee";
import state from "./app/state";
import city from "./app/city";
import master from "./app/master";
import unit from "./app/unit";
import workshop from "./app/workshop";
import order from "./app/order";
import sale from "./app/sale";
import product from "./app/product";
import purchase from "./app/purchase";
import finance from "./app/finance";
import inventory from "./app/inventory";
import contact from "./app/contact";
import currency from "./app/currency";
import setting from "./app/setting";
import attachment from "./app/attachment";
import shipping from "./app/shipping";
import warehouse from "./app/warehouse";
import invoice from "./app/invoice";
import organization from "./app/organization";
import promo from "./app/promo";
import notification from "./app/notification";
import approval from "./app/approval";
import publics from "./app/publics";
import voucher from "./app/voucher";
import leasing from "./app/leasing";
import print from './app/print';

export default createStore({
  state: {
    sidebarCollasp: false,
    sidebarHidden: false,
    mobielSidebar: false,
    semidark: false,
    semiDarkTheme: "semi-light",
    isDark: false,
    skin: "default",
    theme: "light",
    isOpenSettings: false,
    cWidth: "full",
    menuLayout: "vertical",
    navbarType: "sticky",
    footerType: "static",
    chartColors: {
      title: "red",
    },
  },
  getters: {
    theme: (state) => state.theme,
    skin: (state) => state.skin,
  },
  mutations: {
    setSidebar(state, obj) {
      state.sidebarCollasp = obj
    },
    setSidebarCollasp(state) {
      state.sidebarCollasp = !state.sidebarCollasp;
    },
    toogleDark(state) {
      state.isDark = !state.isDark;
      state.theme = state.theme === "dark" ? "light" : "dark";
      document.body.classList.toggle(state.theme);
      localStorage.setItem("theme", state.theme);
    },

    toggleSettings(state) {
      state.isOpenSettings = !state.isOpenSettings;
    },
    toggleMsidebar(state) {
      state.mobielSidebar = !state.mobielSidebar;
    },
    toggleSemiDark(state) {
      state.semidark = !state.semidark;
      state.semiDarkTheme = state.semidark ? "semi-dark" : "semi-light";
      document.body.classList.toggle(state.semiDarkTheme);
      localStorage.setItem("semiDark", state.semidark);
    },
  },
  actions: {
    // toogleDark
    toogleDark({ commit }) {
      commit("toogleDark");
    },
    // toggleSettings
    toggleSettings({ commit }) {
      commit("toggleSettings");
    },
    // setSidebarCollasp
    setSidebarCollasp({ commit }) {
      commit("setSidebarCollasp");
    },
    disableSideBar({ commit }) {
      commit("setSidebar", false)
    },
    enableSideBar({ commit }) {
      commit("setSidebar", true)
    },
    // toggleMsidebar
    toggleMsidebar({ commit }) {
      commit("toggleMsidebar");
    },
    toggleSemiDark({ commit }) {
      commit("toggleSemiDark");
    },

    // setTheme
  },
  modules: {
    apptodo,
    project,
    kanban,
    email,
    chat,
    user,
    auth,
    employee,
    state,
    city,
    master,
    unit,
    order,
    workshop,
    sale,
    product,
    purchase,
    finance,
    inventory,
    contact,
    currency,
    setting,
    attachment,
    shipping,
    warehouse,
    invoice,
    organization,
    promo,
    notification,
    approval,
    publics,
    voucher,
    leasing,
    print
  },
});
