import axios from "axios";
import { getHeader, workshopUrl } from "@/constant/config";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  state: {
    serviceCheckList: [],
    serviceCheckListLevel: [],
    workshopTypeList: [],
    workshopList: [],
    workshopListOutstanding: [],
    workshopDetailList: [],
    workshop: {
      id: null,
      workshop_no: null,
      details: [],
      checklists: [],
      unit: {}
    },
    showWorkshopFormModal: false,
    showWorkshopShowModal: false,
    showWorkshopListModal: false,
    showWorkshopCreateNextModal: false,
    showWorkshopDetailListModal: false,
    showWorkshopReturFormModal: false,
    showWorkshopDetailFormModal: false,
    showWorkshopPaymentFormModal: false,
    showWorkshopPauseNotesModal: false,
    showServiceChecklistFormModal: false,
    showServiceChecklistLevelFormModal: false
  },
  mutations: {
    SET_SERVICE_CHECK_LEVEL_LIST (state, obj) {
      state.serviceCheckListLevel = obj
    },
    SET_SERVICE_CHECK_LIST (state, obj) {
      state.serviceCheckList = obj
    },
    SET_WORKSHOP_TYPE_LIST (state, obj) {
      state.workshopTypeList = obj
    },
    SET_WORKSHOP_LIST (state, obj) {
      state.workshopList = obj
    },
    SET_WORKSHOP_LIST_OUTSTANDING (state, obj) {
      state.workshopListOutstanding = obj
    },
    SET_WORKSHOP_DETAIL_LIST (state, obj) {
      state.workshopDetailList = obj
    },
    SET_WORKSHOP (state, obj) {
      state.workshop = obj
    },
    SET_WORKSHOP_LIST_BY_CUSTOMER (state, obj) {
      state.workshopList = obj
    },
    SET_SHOW_WORKSHOP_FORM_MODAL (state, obj) {
      state.showWorkshopFormModal = obj
    },
    SET_SHOW_WORKSHOP_SHOW_MODAL (state, obj) {
      state.showWorkshopShowModal = obj
    },
    SET_SHOW_WORKSHOP_LIST_MODAL (state, obj) {
      state.showWorkshopListModal = obj
    },
    SET_SHOW_WORKSHOP_CREATE_NEXT_MODAL (state, obj) {
      state.showWorkshopCreateNextModal = obj
    },
    SET_SHOW_WORKSHOP_DETAIL_LIST_MODAL (state, obj) {
      state.showWorkshopDetailListModal = obj
    },
    SET_SHOW_WORKSHOP_RETUR_FORM_MODAL (state, obj) {
      state.showWorkshopReturFormModal = obj
    },
    SET_SHOW_WORKSHOP_DETAIL_FORM_MODAL (state, obj) {
      state.showWorkshopDetailFormModal = obj
    },
    SET_SHOW_WORKSHOP_PAYMENT_FORM_MODAL (state, obj) {
      state.showWorkshopPaymentFormModal = obj
    },
    SET_SHOW_WORKSHOP_PAUSE_NOTES_MODAL (state, obj) {
      state.showWorkshopPauseNotesModal = obj
    },
    SET_SHOW_SERVICE_CHECKLIST_FORM_MODAL (state, obj) {
      state.showServiceChecklistFormModal = obj
    },
    SET_SHOW_SERVICE_CHECKLIST_LEVEL_FORM_MODAL (state, obj) {
      state.showServiceChecklistLevelFormModal = obj
    },
  },
  actions: {
    enableShowServiceChecklistLevelFormModal ({ commit }) {
      commit('SET_SHOW_SERVICE_CHECKLIST_LEVEL_FORM_MODAL', true)
    },
    disableShowServiceChecklistLevelFormModal ({ commit }) {
      commit('SET_SHOW_SERVICE_CHECKLIST_LEVEL_FORM_MODAL', false)
    },
    enableShowServiceChecklistFormModal ({ commit }) {
      commit('SET_SHOW_SERVICE_CHECKLIST_FORM_MODAL', true)
    },
    disableShowServiceChecklistFormModal ({ commit }) {
      commit('SET_SHOW_SERVICE_CHECKLIST_FORM_MODAL', false)
    },
    enableShowWorkshopPauseNotesModal ({ commit }) {
      commit('SET_SHOW_WORKSHOP_PAUSE_NOTES_MODAL', true)
    },
    disableShowWorkshopPauseNotesModal ({ commit }) {
      commit('SET_SHOW_WORKSHOP_PAUSE_NOTES_MODAL', false)
    },
    enableShowWorkshopPaymentFormModal ({ commit }) {
      commit('SET_SHOW_WORKSHOP_PAYMENT_FORM_MODAL', true)
    },
    disableShowWorkshopPaymentFormModal ({ commit }) {
      commit('SET_SHOW_WORKSHOP_PAYMENT_FORM_MODAL', false)
    },
    enableShowWorkshopDetailFormModal ({ commit }) {
      commit('SET_SHOW_WORKSHOP_DETAIL_FORM_MODAL', true)
    },
    disableShowWorkshopDetailFormModal ({ commit }) {
      commit('SET_SHOW_WORKSHOP_DETAIL_FORM_MODAL', false)
    },
    enableShowWorkshopReturFormModal ({ commit }) {
      commit('SET_SHOW_WORKSHOP_RETUR_FORM_MODAL', true)
    },
    disableShowWorkshopReturFormModal ({ commit }) {
      commit('SET_SHOW_WORKSHOP_RETUR_FORM_MODAL', false)
    },
    enableShowWorkshopDetailListModal ({ commit }) {
      commit("SET_SHOW_WORKSHOP_DETAIL_LIST_MODAL", true)
    },
    disableShowWorkshopDetailListModal ({ commit }) {
      commit("SET_SHOW_WORKSHOP_DETAIL_LIST_MODAL", false)
    },
    enableShowWorkshopCreateNextModal ({ commit }) {
      commit('SET_SHOW_WORKSHOP_CREATE_NEXT_MODAL', true)
    },
    disableShowWorkshopCreateNextModal ({ commit }) {
      commit('SET_SHOW_WORKSHOP_CREATE_NEXT_MODAL', false)
    },
    enableShowWorkshopShowModal ({ commit }) {
      commit('SET_SHOW_WORKSHOP_SHOW_MODAL', true)
    },
    disableShowWorkshopShowModal ({ commit }) {
      commit('SET_SHOW_WORKSHOP_SHOW_MODAL', false)
    },
    enableShowWorkshopFormModal ({ commit }) {
      commit('SET_SHOW_WORKSHOP_FORM_MODAL', true)
    },
    disableShowWorkshopFormModal ({ commit }) {
      commit('SET_SHOW_WORKSHOP_FORM_MODAL', false)
    },
    enableShowWorkshopListModal ({ commit }) {
      commit('SET_SHOW_WORKSHOP_LIST_MODAL', true)
    },
    disableShowWorkshopListModal ({ commit }) {
      commit('SET_SHOW_WORKSHOP_LIST_MODAL', false)
    },
    async getWorkshopDetailList ({ commit }, payload) {
      try {
        const data = await axios.post(workshopUrl + '/list-detail', payload, { headers: getHeader() })
        commit('SET_WORKSHOP_DETAIL_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getWorkshopListOutstanding ({ commit }, payload) {
      try {
        console.log(workshopUrl + '/list-outstanding')
        console.log(payload)
        const data = await axios.post(workshopUrl + '/list-outstanding', payload, { headers: getHeader() })
        commit('SET_WORKSHOP_LIST_OUTSTANDING', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getWorkshopList ({ commit }, payload) {
      // contact_no, begin, end
      console.log(payload)
      try {
        const data = await axios.post(workshopUrl + '/list', payload, { headers: getHeader() })
        commit('SET_WORKSHOP_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getWorkshop({ commit }, payload) {
      try {
        const data = await axios.get(workshopUrl + '/' + payload.id, { headers: getHeader() })
        commit('SET_WORKSHOP', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getWorkshopByNo({ commit }, payload) {
      try {
        const obj = {
          workshop_no: payload.workshop_no
        }
        const data = await axios.post(workshopUrl + '/get-workshop-no', obj, { headers: getHeader() })
        commit('SET_WORKSHOP', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getServiceCheckListLevel({ commit }) {
      try {
        const data = await axios.get(workshopUrl + '/service-checklist-level', { headers: getHeader() })
        commit('SET_SERVICE_CHECK_LEVEL_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getServiceCheckList({ commit }) {
      try {
        const data = await axios.get(workshopUrl + '/service-checklist', { headers: getHeader() })
        commit('SET_SERVICE_CHECK_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getWorkshopTypeList({ commit }) {
      try {
        const data = await axios.get(workshopUrl + '/type', { headers: getHeader() })
        commit('SET_WORKSHOP_TYPE_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getWorkshopByCustomer({ commit }, payload) {
      try {
        const data = await axios.post(workshopUrl + '/list-by-customer', payload, { headers: getHeader() })
        commit('SET_WORKSHOP_LIST_BY_CUSTOMER', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
  }
};
