export const domain = process.env.VUE_APP_DOMAIN
export const apiDomain = domain + '/api/v1/'
export const timeoutAPP = process.env.VUE_APP_TIMEOUT

export const loginUrl = apiDomain + 'auth'
export const masterUrl = apiDomain + 'master-service'

// AUTH Service
export const authServiceUrl = apiDomain + 'auth-service/'
export const userTokenUrl = authServiceUrl + 'user/token'
export const userUrl = authServiceUrl + 'user'
export const timezoneUrl = authServiceUrl + 'user/timezone'
export const roleUrl = authServiceUrl + 'user/role'
export const organizationUrl = authServiceUrl + 'organization'
export const employeeUrl = authServiceUrl + 'employee'
export const branchUrl = authServiceUrl + 'branch'
// Master Service
export const masterServiceUrl = apiDomain + 'master-service/'
export const paymentMethodUrl = masterServiceUrl + 'payment/method'
export const vendorUrl = masterServiceUrl + 'vendor'
export const stateUrl = masterServiceUrl + 'state'
export const cityUrl = masterServiceUrl + 'city'
export const uomUrl = masterServiceUrl + 'uom'
export const productUrl = masterServiceUrl + 'product'
export const productTypeUrl = productUrl + '/type'
export const productCategoryUrl = productUrl + '/category'
export const customerUrl = masterServiceUrl + 'customer'
export const taxUrl = masterServiceUrl + 'tax'
export const contactUrl = masterServiceUrl + 'contact'
export const countryUrl = masterServiceUrl + 'country'
export const currencyUrl = masterServiceUrl + 'currency'
export const exchangeUrl = masterServiceUrl + 'exchange'
export const settingUrl = masterServiceUrl + 'setting'
export const warehouseUrl = masterServiceUrl + 'warehouse'
export const priceListUrl = masterServiceUrl + 'price-list'
export const leasingUrl = masterServiceUrl + 'leasing'
export const notificationUrl = masterServiceUrl + 'notification'
export const bankUrl = masterServiceUrl + 'bank'
// Transaction service
export const transactionUrl = apiDomain + 'transaction-service'
export const transactionServiceUrl = apiDomain + 'transaction-service/'
export const purchaseUrl = transactionServiceUrl + 'purchase'
export const saleUrl = transactionServiceUrl + 'sale'
export const saleDetailUrl = saleUrl + '/detail'
export const saleDocUrl = saleUrl + '/document'
export const workshopUrl = transactionServiceUrl + 'workshop'
export const unitUrl = transactionServiceUrl + 'unit'
export const unitCheckUrl = unitUrl + '/check'
export const unitTripUrl = unitUrl + '/trip'
export const orderUrl = transactionServiceUrl + 'order'
export const paymentUrl = transactionServiceUrl + 'payment'
export const financeUrl = transactionServiceUrl + 'finance'
export const inventoryUrl = transactionServiceUrl + 'inventory'
export const shippingUrl = transactionServiceUrl + 'shipping'
export const attachmentUrl = transactionServiceUrl + 'attachment'
export const invoiceUrl = transactionServiceUrl + 'invoice'
export const lockUrl = transactionServiceUrl + 'lock'
export const returUrl = transactionServiceUrl + 'retur'
export const promoUrl = transactionServiceUrl + 'promo'
export const voucherUrl = transactionServiceUrl + 'voucher'
export const approvalUrl = transactionServiceUrl + 'approval'
export const logUrl = transactionServiceUrl + 'log'

// Service V2
export const serviceV2Url = domain + '/api/v2/'
export const authV2Url = serviceV2Url + 'auth'
export const masterV2Url = serviceV2Url + 'master'
export const transactionV2Url = serviceV2Url + 'transaction'

export const getHeader = function () {
  const tokenData = JSON.parse(window.localStorage.getItem('authUser'))
  const headers = {
    Accept: 'application/json',
    Authorization: 'Bearer ' + tokenData.token
  }
  return headers
}
