import { getHeader, inventoryUrl } from "@/constant/config";
import axios from "axios";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  state: {
    inventory: {
      id: null,
      inventory: null,
      details: [{
        id: null,
        inventory_no: null
      }],
      detail: {},
      receipt: [],
      delivery: [],
      warranty: [],
      scrap: [],
      sales: [],
      purchases: [],
      workshops: []
    },
    inventoryDetailList: [],
    inventoryListFor: [],
    inventoryUnitList: [],
    inventoryDocumentList: [],
    showInventoryImportFormModal: false,
    showInventoryListForModal: false,
    showInventoryListModal: false,
    showInventoryShowModal: false,
    showInventoryDetailListModal: false,
    showInventoryDetailFormModal: false,
    showInventoryUnitListModal: false,
    showInventoryDetailTransferListModal: false,
    showInventoryDetailShowModal: false,
    showInventoryDocumentListModal: false,
    showInventoryImportApiExternalModal: false
  },
  mutations: {
    SET_INVENTORY_DETAIL_LIST (state, obj) {
      state.inventoryDetailList = obj
    },
    SET_INVENTORY_LIST_FOR (state, obj) {
      state.inventoryListFor = obj
    },
    SET_INVENTORY_IMPORT_FORM_MODAL (state, obj) {
      state.showInventoryImportFormModal = obj
    },
    SET_SHOW_INVENTORY_LIST_FOR_MODAL (state, obj) {
      state.showInventoryListForModal = obj
    },
    SET_SHOW_INVENTORY_LIST_MODAL (state, obj) {
      state.showInventoryListModal = obj
    },
    SET_SHOW_INVENTORY_SHOW_MODAL (state, obj) {
      state.showInventoryShowModal = obj
    },
    SET_INVENTORY (state, obj) {
      state.inventory = obj
    },
    SET_INVENTORY_DETAIL (state,obj) {
      state.detail = obj
    },
    SET_SHOW_INVENTORY_DETAIL_MODAL (state, obj) {
      state.showInventoryDetailListModal = obj
    },
    SET_SHOW_INVENTORY_DETAIL_FORM_MODAL (state, obj) {
      state.showInventoryDetailFormModal = obj
    },
    SET_SHOW_INVENTORY_UNIT_LIST_MODAL (state, obj) {
      state.showInventoryUnitListModal = obj
    },
    SET_INVENTORY_UNIT_LIST (state, obj) {
      state.inventoryUnitList = obj
    },
    SET_INVENTORY_TRANSFER_LIST_MODAL (state, obj) {
      state.showInventoryDetailTransferListModal = obj
    },
    SET_INVENTORY_DETAIL_SHOW_MODAL (state, obj) {
      state.showInventoryDetailShowModal = obj
    },
    SET_INVENTORY_DOCUMENT_LIST (state, obj) {
      state.inventoryDocumentList = obj
    },
    SET_INVENTORY_DOCUMENT_LIST_SHOW_MODAL (state, obj) {
      state.showInventoryDocumentListModal = obj
    },
    SET_INVENTORY_IMPORT_API_EXTERNAL_MODAL (state, obj) {
      state.showInventoryImportApiExternalModal = obj
    }
  },
  actions: {
    enableInventoryImportApiExternalModal ({ commit }) {
      commit('SET_INVENTORY_IMPORT_API_EXTERNAL_MODAL', true)
    },
    disableInventoryImportApiExternalModal ({ commit }) {
      commit('SET_INVENTORY_IMPORT_API_EXTERNAL_MODAL', false)
    },
    enableInventoryDocumentListShowModal ({ commit }) {
      commit('SET_INVENTORY_DOCUMENT_LIST_SHOW_MODAL', true)
    },
    disableInventoryDocumentListShowModal ({ commit }) {
      commit('SET_INVENTORY_DOCUMENT_LIST_SHOW_MODAL', false)
    },
    enableInventoryDetailShowModal ({ commit }) {
      commit('SET_INVENTORY_DETAIL_SHOW_MODAL', true)
    },
    disableInventoryDetailShowModal ({ commit }) {
      commit('SET_INVENTORY_DETAIL_SHOW_MODAL', false)
    },
    enableShowInventoryTransferListModal ({ commit }) {
      commit('SET_INVENTORY_TRANSFER_LIST_MODAL', true)
    },
    disableShowInventoryTransferListModal ({ commit }) {
      commit('SET_INVENTORY_TRANSFER_LIST_MODAL', false)
    },
    enableShowInventoryUnitListModal ({ commit }) {
      commit('SET_SHOW_INVENTORY_UNIT_LIST_MODAL', true)
    },
    disableShowInventoryUnitListModal ({ commit }) {
      commit('SET_SHOW_INVENTORY_UNIT_LIST_MODAL', false)
    },
    enableInventoryDetailFormModal ({ commit }) {
      commit('SET_SHOW_INVENTORY_DETAIL_FORM_MODAL', true)
    },
    disableInventoryDetailFormModal ({ commit }) {
      commit('SET_SHOW_INVENTORY_DETAIL_FORM_MODAL', false)
    },
    enableInventoryDetailModal ({ commit }) {
      commit('SET_SHOW_INVENTORY_DETAIL_MODAL', true)
    },
    disableInventoryDetailModal ({ commit }) {
      commit('SET_SHOW_INVENTORY_DETAIL_MODAL', false)
    },
    enableInventoryShowModal ({ commit }) {
      commit('SET_SHOW_INVENTORY_SHOW_MODAL', true)
    },
    disableInventoryShowModal ({ commit }) {
      commit('SET_SHOW_INVENTORY_SHOW_MODAL', false)
    },
    enableInventoryImportFormModal ({ commit }) {
      commit('SET_INVENTORY_IMPORT_FORM_MODAL', true)
    },
    disableInventoryImportFormModal ({ commit }) {
      commit('SET_INVENTORY_IMPORT_FORM_MODAL', false)
    },
    enableInventoryListForModal ({ commit }) {
      commit('SET_SHOW_INVENTORY_LIST_FOR_MODAL', true)
    },
    disableInventoryListForModal ({ commit }) {
      commit('SET_SHOW_INVENTORY_LIST_FOR_MODAL', false)
    },
    enableInventoryListModal ({ commit }) {
      commit('SET_SHOW_INVENTORY_LIST_MODAL', true)
    },
    disableInventoryListModal ({ commit }) {
      commit('SET_SHOW_INVENTORY_LIST_MODAL', false)
    },
    async getInventoryDocumentList ({ commit }, payload) {
      try {
        const data = await axios.post(inventoryUrl + '/document/list', payload, { headers: getHeader() })
        commit('SET_INVENTORY_DOCUMENT_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getInventoryDetail ({ commit}, payload) {
      try {
        const data = await axios.post(inventoryUrl + '/detail/show', payload, { headers: getHeader() })
        commit("SET_INVENTORY_DETAIL", data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getInventoryDetailList ({ commit }, payload) {
      try {
        const data = await axios.post(inventoryUrl + '/detail', payload, { headers: getHeader() })
        commit('SET_INVENTORY_DETAIL_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getInventoryListFor ({ commit }, payload) {
      try {
        const data = await axios.post(inventoryUrl + '/list', payload, { headers: getHeader() })
        commit('SET_INVENTORY_LIST_FOR', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getInventory ({ commit }, payload) {
      try {
        console.log(inventoryUrl + '/' + payload.id)
        const data = await axios.get(inventoryUrl + '/' + payload.id, { headers: getHeader() })
        commit('SET_INVENTORY', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getInventoryByNo ({ commit }, payload) {
      try {
        const data = await axios.post(inventoryUrl + '/get-by-no', payload, { headers: getHeader() })
        commit('SET_INVENTORY', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getInventoryUnitList ({ commit }, payload) {
      try {
        const data = await axios.post(inventoryUrl + '/detail/unit-list', payload, { headers: getHeader() })
        commit('SET_INVENTORY_UNIT_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    }
  }
};
