import axios from "axios";
import { authV2Url, masterV2Url, transactionV2Url } from "@/constant/config";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  state: {
    publicOrganization: {},
    publicEmployeeList: [],
    publicProductList: [],
    publicCityList: [],
    publicCurrencyList: [],
    publicUomList: [],
    publicTaxList: [],
    publicTermOfPaymentList: [],
    publicPaymentMethodList: [],
    publicCustomerList: [],
    publicOrderTypeList: [],
    publicWorkshopTypeList: []
  },
  mutations: {
    PUBLIC_ORGANIZATION (state, obj) {
      state.publicOrganization = obj
    },
    PUBLIC_EMPLOYEE_LIST (state, obj) {
      state.publicEmployeeList = obj
    },
    PUBLIC_PRODUCT_LIST (state, obj) {
      state.publicProductList = obj
    },
    PUBLIC_CITY_LIST (state, obj) {
      state.publicCityList = obj
    },
    PUBLIC_CURRENCY_LIST (state, obj) {
      state.publicCurrencyList = obj
    },
    PUBLIC_UOM_LIST (state, obj) {
      state.publicUomList = obj
    },
    PUBLIC_TAX_LIST (state, obj) {
      state.publicTaxList = obj
    },
    PUBLIC_TERM_OF_PAYMENT_LIST (state, obj) {
      state.publicTermOfPaymentList = obj
    },
    PUBLIC_PAYMENT_METHOD_LIST (state, obj) {
      state.publicPaymentMethodList = obj
    },
    PUBLIC_CUSTOMER_LIST (state, obj) {
      state.publicCustomerList = obj
    },
    PUBLIC_ORDER_TYPE_LIST (state, obj) {
      state.publicOrderTypeList = obj
    },
    PUBLIC_WORKSHOP_TYPE_LIST (state, obj) {
      state.publicWorkshopTypeList = obj
    }
  },
  actions: {
    async publicOrganization({ commit }, payload) {
      const url = authV2Url + '/organization'
      console.log(url)
      try {
        const data = await axios.post(url, { org_no: payload })
        commit('PUBLIC_ORGANIZATION', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async publicEmployeeList({ commit }, payload) {
      const url = authV2Url + '/employee-list?org=' + payload.org_no
      console.log(url)
      try {
        const data = await axios.get(url)
        commit('PUBLIC_EMPLOYEE_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async publicProductList({ commit }, payload) {
      const url = masterV2Url + '/product/listed?org=' + payload.org_no
      console.log(url)
      try {
        const data = await axios.get(url)
        commit('PUBLIC_PRODUCT_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async publicCityList({ commit }, payload) {
      const url = masterV2Url + '/city-list'
      console.log(url)
      try {
        const data = await axios.get(url)
        commit('PUBLIC_CITY_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async publicUomList({ commit }, payload) {
      const url = masterV2Url + '/uom-list?org=' + payload.org_no
      console.log(url)
      try {
        const data = await axios.get(url)
        commit('PUBLIC_UOM_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async publicTaxList({ commit }, payload) {
      const url = masterV2Url + '/tax-list?org=' + payload.org_no
      console.log(url)
      try {
        const data = await axios.get(url)
        commit('PUBLIC_TAX_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async publicCurrencyList({ commit }, payload) {
      const url = masterV2Url + '/currency-list?org=' + payload.org_no
      console.log(url)
      try {
        const data = await axios.get(url)
        commit('PUBLIC_CURRENCY_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async publicTermOfPaymentList({ commit }, payload) {
      const url = masterV2Url + '/term-of-payment-list?org=' + payload.org_no
      console.log(url)
      try {
        const data = await axios.get(url)
        commit('PUBLIC_TERM_OF_PAYMENT_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async publicPaymentMethodList({ commit }, payload) {
      const url = masterV2Url + '/payment-method-list?org=' + payload.org_no
      console.log(url)
      try {
        const data = await axios.get(url)
        commit('PUBLIC_PAYMENT_METHOD_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async publicCustomerList({ commit }, payload) {
      const url = masterV2Url + '/customer-list?org=' + payload.org_no + '&branch='
      console.log(url)
      try {
        const data = await axios.get(url)
        commit('PUBLIC_CUSTOMER_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async publicOrderTypeList({ commit }, payload) {
      const url = transactionV2Url + '/order-type?org=' + payload.org_no
      console.log(url)
      try {
        const data = await axios.get(url)
        commit('PUBLIC_ORDER_TYPE_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async publicWorkshopTypeList({ commit }, payload) {
      const url = transactionV2Url + '/workshop-type?org=' + payload.org_no
      console.log(url)
      try {
        const data = await axios.get(url)
        commit('PUBLIC_WORKSHOP_TYPE_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    }
  }
};
