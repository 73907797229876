import axios from "axios";
import { notificationUrl, getHeader } from "@/constant/config";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  state: {
    notificationTemplateList: []
  },
  mutations: {
    SET_TEMPLATE_LIST (state, obj) {
      state.notificationTemplateList = obj
    }
  },
  actions: {
    async getTemplateList({ commit }) {
      try {
        const data = await axios.get(notificationUrl + '/template', { headers: getHeader() })
        commit('SET_TEMPLATE_LIST', data.data.data)
      } catch (error) {
        toast.error(error.message, { timeout: 2000 })
      }
    }
  },
};
