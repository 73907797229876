export default {
  state: {
    authUser: {
      permission: {
        "dashboard-view": false,
        "purchase-view": false,
        "inventory-view": false,
        "sale-view": false,
        "workshop-view": false,
        "unit-view": false,
        "invoice-view": false,
        "finance-view": false,
        "setting-view": false,
        "setting-view": false,
        "state-view": false,
        "city-view": false,
        "organization-view": false,
        "user-view": false,
        "employee-view": false,
        "vendor-view": false,
        "customer-view": false,
        "uom-view": false,
        "method-view": false,
        "tax-view": false,
        "currency-view": false,
        "exchange-view": false,
        "setting": false,
        "branch-view": false,
        "bank-view": false
      }
    },
  },
  getters: {
  //   getprofileinfo: (state) => state.profileinfo
  },
  mutations: {
    SET_AUTH_USER (state, obj) {
      state.authUser = obj
    }
  },
  actions: {
    setUserObject: ({ commit }, payload) => {
      commit('SET_AUTH_USER', payload)
    }
  }
};
  