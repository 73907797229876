import axios from "axios"
import { getHeader, leasingUrl } from "@/constant/config"

export default {
  state: {
    leasing: {},
    leasingList: [],
    leasingGroupList: [],
    showLeasingFormModal: false,
  },
  mutations: {
    SET_LEASING (state, obj) {
      state.leasing = obj
    },
    SET_LEASING_LIST (state, obj) {
      state.leasingList = obj
    },
    SET_LEASING_GROUP_LIST (state, obj) {
      state.leasingGroupList = obj
    },
    SET_SHOW_LEASING_FORM_MODAL (state, obj) {
      state.showLeasingFormModal = obj
    }
  },
  actions: {
    enableShowLeasingFormModal ({ commit }) {
      commit('SET_SHOW_LEASING_FORM_MODAL', true)
    },
    disableShowLeasingFormModal ({ commit }) {
      commit('SET_SHOW_LEASING_FORM_MODAL', false)
    },
    async getLeasingGroupList ({ commit }) {
      try {
        console.log(leasingUrl + '/group')
        const data = await axios.get(leasingUrl + '/group', { headers: getHeader() })
        commit("SET_LEASING_GROUP_LIST", data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
      }
    },
    async getLeasingList ({ commit }) {
      try {
        const data = await axios.get(leasingUrl, { headers: getHeader() })
        commit("SET_LEASING_LIST", data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
      }
    },
    async getLeasing ({ commit }, payload) {
      try {
        const data = await axios.get(leasingUrl + '/' + payload.id, { headers: getHeader() })
        commit("SET_LEASING", data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
      }
    }
   }
};
