import axios from "axios";
import { getHeader, voucherUrl, warehouseUrl } from "@/constant/config";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  state: {
    voucherList: [],
    voucher: {},
    showVoucherModal: false,
  },
  mutations: {
    SET_VOUCHER_LIST (state, obj) {
      state.voucherList = obj
    },
    SET_VOUCHER (state, obj) {
      state.voucher = obj
    },
    SET_SHOW_VOUCHER_MODAL (state, obj) {
      state.showVoucherModal = obj
    }
  },
  actions: {
    enableShowVoucherModal ({ commit }) {
      commit('SET_SHOW_VOUCHER_MODAL', true)
    },
    disableShowVoucherModal ({ commit }) {
      commit('SET_SHOW_VOUCHER_MODAL', false)
    },
    async getVoucherList({ commit }) {
      try {
        const data = await axios.get(voucherUrl, { headers: getHeader() })
        commit('SET_VOUCHER_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getVoucher({ commit }, payload) {
      try {
        const data = await axios.get(voucherUrl + '/' + payload.id, { headers: getHeader() })
        commit('SET_VOUCHER', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getVoucherByNo({ commit }, payload) {
      try {
        const data = await axios.get(voucherUrl + '/' + payload.voucher_no, { headers: getHeader() })
        commit('SET_VOUCHER', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    }
  }
};
