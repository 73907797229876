export default {
  state: {
    showPrintModal: false
  },
  mutations: {
    SET_SHOW_PRINT_MODAL (state, obj) {
      state.showPrintModal = obj
    }
  },
  actions: {
    enableShowPrintModal({ commit }) {
      commit('SET_SHOW_PRINT_MODAL', true)
    },
    disableShowPrintModal({ commit }) {
      commit('SET_SHOW_PRINT_MODAL', false)
    }
  }
};
